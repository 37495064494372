<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
          v-if="skin === 'dark'"
          style="max-width: 140px"
          src="@/assets/images/logo/GRC_DARK_LOGO.png"
          alt="logo"
        />
        <b-img
          v-else
          style="max-width: 140px"
          src="@/assets/images/logo/GRC_LOGO.png"
          alt="logo"
        />
        <!-- <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">CisoGRC</h2> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Welcome to Secusy 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account.
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group label="Email" label-for="login-email">
                <!-- <template slot="label">
                  Email <span class="text-danger">*</span>
                </template> -->
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{ name: 'forgot-password' }">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <template slot="label">
                    Password <span class="text-danger">*</span>
                  </template>
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                Sign in
              </b-button>
            </b-form>
          </validation-observer>

          <!-- <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{ name: 'register' }">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text>

        
          <div class="divider my-2">
            <div class="divider-text">or</div>
          </div>

         
          <div class="auth-footer-btn d-flex justify-content-center">
            <b-button variant="facebook" href="javascript:void(0)">
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button variant="twitter" href="javascript:void(0)">
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button variant="google" href="javascript:void(0)">
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button variant="github" href="javascript:void(0)">
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ResponseMixins from "../../mixins/ResponseMixins";
import ThirdPartyRisksMixins from "../../mixins/ThirdPartyRisksMixins";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility, ResponseMixins, ThirdPartyRisksMixins],

  setup(props) {
    const { skin } = useAppConfig();

    return { skin };
  },

  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          const data = {
            email: this.userEmail,
            password: this.password,
          };
          this.$store
            .dispatch("app/login", data, { root: true })
            .then((res) => {
              let first_auth_token = res.data.data.data
                ? res.data.data.data.first_auth_token
                : "";
              let is_2fa_enabled = res.data.data.data
                ? res.data.data.data.is_2fa_enabled
                : "";
              if (first_auth_token && is_2fa_enabled) {
                // const url = "/2FA";
                // this.$router.replace(url);
                this.$router.push({
                  name: "TwoFA",
                  params: { id: res.data.data.data.first_auth_token },
                });
              } else {
                const url = "/";
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: `Welcome ${this.$store.state.app.user.firstname} ${this.$store.state.app.user.lastname}`,
                    icon: "UserCheckIcon",
                    variant: "success",
                  },
                });
                return this.$router.replace(url);
                // this.$store
                //   .dispatch("app/getCurrentUserDepartment", { root: true })
                //   .then((resp) => {

                //     //  console.log('Dashboard CurrentId', $cookies.get('currentCID'), Date.now())
                //     // console.log(resp)
                //     //------------If User is Vendor-------------------------
                //     if (
                //       resp.data.data.user_type &&
                //       resp.data.data.user_type == "Vendor"
                //     ) {
                //       this.$store.commit("app/SET_USER_DATA", resp.data.data);
                //       const url = "/";
                //       this.$toast({
                //         component: ToastificationContent,
                //         props: {
                //           title: `Welcome ${resp.data.data.firstname} ${resp.data.data.lastname}`,
                //           icon: "UserCheckIcon",
                //           variant: "success",
                //         },
                //       });
                //       return this.$router.replace(url);
                //     }
                //     // ---------------------If User is normal User
                //     else {
                //       if (resp.data.data.department !== null) {
                //         const url = "/";
                //         this.$toast({
                //           component: ToastificationContent,
                //           props: {
                //             title: `Welcome ${resp.data.data.firstname} ${resp.data.data.lastname}`,
                //             icon: "UserCheckIcon",
                //             variant: "success",
                //           },
                //         });
                //         return this.$router.replace(url);
                //       } else {
                //         const url = "/settings";
                //         this.$toast({
                //           component: ToastificationContent,
                //           props: {
                //             title: "Please complete your profile & continue",
                //             icon: "AlertTriangleIcon",
                //             variant: "warning",
                //           },
                //         });
                //         return this.$router.replace(url);
                //       }
                //     }
                //   })
                //   .catch((err) => {
                //     console.log(err);

                //     const url = "/login";
                //     this.$router.replace(url);
                //     this.handleError(err);
                //   });
              }
            })
            .catch((err) => {
              console.log(err);

              //If the Error response contains data
              if (
                err.response &&
                typeof err.response.data.data !== "undefined" &&
                typeof err.response.data.data.is_user_blocked !== "undefined" &&
                err.response.data.data.is_user_blocked == true &&
                typeof err.response.data.data.user_can_reset !== "undefined" &&
                err.response.data.data.user_can_reset == true
              ) {
                this.$router.push("/forgot-password").then(() => {
                  this.showToast(
                    "Your account has been temporarily blocked due to security reasons. You can either reset your password or contact admin to regain access to your account",
                    "danger"
                  );
                });
              } else {
                this.handleError(err);
              }
            })
            .finally(() => {
              // localStorage.removeItem("from_url");
              // this.$router.go()
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
